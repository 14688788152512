<template>
  <section class="feature-with-carousel">
    <UiHeader
      v-if="header"
      class="feature-with-carousel__header"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-position="headerPosition"
      :header-size="headerSize"
    />

    <template v-if="galleryGroup.length">
      <UiSwiper
        :options="swiperOptions"
        class="feature-with-carousel__slider"
      >
        <SwiperSlide
          v-for="(slide, index) in galleryGroup"
          :key="index"
          class="feature-with-carousel__group"
        >
          <div
            v-for="image in slide"
            :key="image.id"
            class="feature-with-carousel__group-item"
          >
            <div
              v-if="image.properties.description"
              class="feature-with-carousel__text"
            >
              <SvgIconQuote class="feature-with-carousel__quote" />

              <span class="feature-with-carousel__desc">
                {{ image.properties.description }}
              </span>
            </div>

            <UiImg
              :image="image"
              class="feature-with-carousel__icon"
            />
          </div>
        </SwiperSlide>
      </UiSwiper>

      <div
        ref="pagination"
        class="swiper-pagination"
      />
    </template>
  </section>
</template>

<script setup lang="ts">
import { Pagination } from 'swiper/modules'
import type { SwiperOptions } from 'swiper/types'
import { SwiperSlide } from 'swiper/vue'
import { computed, ref } from 'vue'

import SvgIconQuote from '~ui/assets/icons/icon-quote.svg'
import { UiHeader, UiImg, UiSwiper } from '~ui/components'
import useFlatGallery from '~ui/composables/useFlatGallery'
import type { UiCeTextGalleryProps } from '~ui/types'

const props = defineProps<UiCeTextGalleryProps>()

const imagesPerSlide = 4

const galleryGroup = computed(() => {
  const flatGallery = useFlatGallery(props)
  const slideList = []

  for (let i = 0; i < flatGallery.value.length; i += imagesPerSlide) {
    slideList.push(flatGallery.value.slice(i, i + imagesPerSlide))
  }

  return slideList
})

const pagination = ref(null)

const swiperOptions = computed(
  (): SwiperOptions => ({
    modules: [Pagination],
    slidesPerView: 1,
    pagination: {
      el: pagination.value,
      type: 'bullets',
      clickable: true,
      bulletElement: 'button'
    }
  })
)
</script>

<style lang="scss">
.feature-with-carousel {
  $base: &;

  position: relative;

  &__header {
    margin-bottom: rem(20px);
  }

  &__group {
    display: grid;
    grid-template: repeat(2, 1fr) / repeat(2, 1fr);

    &-item {
      display: grid;
      align-content: space-between;
      gap: rem(32px);
      background-color: color(alabaster);
      padding: rem(21px);
      box-shadow: 0px 1px 8px -2px hsl(0 0% 0% / 15%);
      margin: 8px;
    }
  }

  & .swiper-pagination {
    margin-top: 10px;
  }

  &__quote {
    display: block;
    height: 32px;
    aspect-ratio: 1;
    margin-bottom: rem(15px);

    & path {
      fill: color(primary);
    }
  }

  &__title {
    white-space: nowrap;

    @include media-query(lg) {
      margin-bottom: 0;
    }
  }

  &__icon {
    height: rem(40px);
    width: auto;
    object-fit: contain;
  }

  &__desc {
    font-size: rem(16px);
    font-style: italic;
    line-height: 1.15;

    @include media-query(530px) {
      font-size: rem(20px);
    }
  }
}
</style>
